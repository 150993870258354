import { Link } from 'gatsby';
import { css } from '@emotion/core';
import React from 'react';
import { BREAKPOINTS, COLORS } from './variables';

interface BaseButton {
  color: COLORS.ORANGE | COLORS.WHITE | COLORS.NEW_ORANGE;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

interface ExternalButton extends BaseButton {
  type: 'external';
  href: string;
}

interface InternalButton extends BaseButton {
  type: 'internal';
  to: string;
}

interface ActionButton extends BaseButton {
  type: 'action';
  onClick: () => void;
}

const ColorHoverMap = {
  [COLORS.NEW_ORANGE || COLORS.ORANGE]: '#C46621',
  [COLORS.WHITE]: COLORS.WHITE,
};

const Button = (props: ExternalButton | InternalButton | ActionButton) => {
  const { color, className, children, onClick, disabled } = props;

  let textColor;
  switch (color) {
    case COLORS.ORANGE:
    case COLORS.NEW_ORANGE:
      textColor = COLORS.WHITE;
      break;
    case COLORS.WHITE:
      textColor = COLORS.ORANGE;
      break;
    default:
      textColor = COLORS.BLACK;
      break;
  }

  const baseButton = css`
    background: ${color};
    color: ${textColor};
    padding: 12px 26px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 28px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    transition: background 200ms ease;

    &:hover {
      background: ${ColorHoverMap[color]};
    }

    @media ${BREAKPOINTS.MOBILE} {
      font-size: 16px;
    }
  `;

  if (props.type === 'internal') {
    return (
      <Link
        css={baseButton}
        className={className}
        onClick={onClick}
        to={props.to || ''}
      >
        {children}
      </Link>
    );
  }

  if (props.type === 'external') {
    return (
      <a css={baseButton} className={className} href={props.href || ''}>
        {children}
      </a>
    );
  }

  return (
    <button
      css={baseButton}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
