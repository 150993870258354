//Set of functions to extract utm parameters from url and save them in a cookie
interface QueryParams {
  gclid?: string;
  sd?: string;
  sdcp?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  [key: string]: string;
}

export function getQueryParams(url: string): QueryParams {
  const queryParams = new URLSearchParams(new URL(url).search);
  const extractedParams: QueryParams = {};

  for (const [key, value] of queryParams.entries()) {
    switch (key) {
      case 'sdcp':
        extractedParams.sdcp = value;
        break;
      case 'sd':
        extractedParams.sd = value;
        break;
      case 'gclid':
        extractedParams.gclid = value;
        break;
      default:
        if (key.startsWith('utm_')) {
          extractedParams[key] = value;
        }
        break;
    }
  }

  return extractedParams;
}

export function isCookieEmpty(cookieName: string) {
  const cookie = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith(`${cookieName}=`));

  if (cookie) {
    const cookieValue = cookie.split('=')[1];
    return cookieValue === '';
  }
  return true;
}

export function isObjectEmpty(object: object) {
  return Object.keys(object).length === 0;
}

export function saveParamsToCookie() {
  const url = location.href;
  const queryParams = getQueryParams(url);

  let dataString;

  if (isObjectEmpty(queryParams)) {
    return;
  } else {
    const dataObject = { ...queryParams, full_path: url };
    dataString = JSON.stringify(dataObject);
  }

  if (isCookieEmpty('smallDoorVet')) {
    const sixtyDays = `${60 * 60 * 24 * 60}`;
    document.cookie = `smallDoorVet=${dataString}; path=/; max-age=${sixtyDays}; domain=.smalldoorvet.com`;
  }
}
