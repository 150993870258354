import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  lang?: string;
  meta?: {
    name: string;
    content: string;
  }[];
  title: string;
  image?: string;
  schema?: object;
  breadcrumbSchema?: object;
}

function SEO({
  description = '',
  lang = 'en',
  meta = [],
  title,
  image,
  schema,
  breadcrumbSchema,
}: Props) {
  const { site, defaultOGImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        defaultOGImage: file(
          relativePath: { eq: "link-preview-image-01.jpg" }
        ) {
          childImageSharp {
            fixed(width: 1000) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  let metaImage = defaultOGImage.childImageSharp.fixed.src;
  if (
    image &&
    image.match(
      /(https?:)?\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    )
  ) {
    metaImage = image;
  } else if (image) {
    metaImage = site.siteMetadata.url + image;
  }

  // Check to ensure that the variable only access the window object when code is running on the client-side and avoid the SSR error during building
  const canonicalUrl =
    typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `Small Door Veterinary`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    >
      {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}
      {schema && (
        <script type='application/ld+json'>{JSON.stringify(schema)}</script>
      )}
      {breadcrumbSchema && (
        <script type='application/ld+json'>
          {JSON.stringify(breadcrumbSchema)}
        </script>
      )}
    </Helmet>
  );
}

export default SEO;
